<template>
  <main class="main home-page">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="wrapper home">
          <div class="left">
            <div class="title big">
              {{ $t("The best place to buy CS2 skins") }}
            </div>
            <div class="desc">
              {{ $te("BuskoSkins offers a wide selection of CS2 skins") ? $t("BuskoSkins offers a wide selection of CS2 skins") : "BuskoSkins offers a wide selection of CS2 skins at competitive prices, user-friendly interface, secure payment system, fast delivery and excellent customer service." }}
            </div>
          </div>
          <div class="right">
            <div class="img-container">
              <img src="./../assets/weapon.png" class="img" />
            </div>
          </div>
        </div>
        <Features />
      </div>

      <LastSales @goToProduct="goToProduct" @addToCart="addToCart"
        :addToCartChosenItem="addToCartChosenItem" />

      <div class="section cases-section">
        <RandomCasesSlider />
      </div>

      <div class="section arrivals-section sale-section">
        <div class="wrapper">
          <div class="top">
            <div class="title">{{ $t("Most Popular") }}</div>
            <router-link to="/products/all" class="link">
              <span>{{ $t("See all") }}</span>
              <img src="./../assets/green-arrow.svg" class="img" />
            </router-link>
          </div>
          <SlickItem v-if="$parent.newArrivals.length" :list="$parent.newArrivals" :tag="''" :addToCartChosenItem="addToCartChosenItem" @addToCart="addToCart" @goToProduct="goToProduct" :currency="currency" />
        </div>
      </div>
      <div class="section more-offers-section">
        <img src="./../assets/more-offers.png" class="img" />
        <div class="wrapper">
          <div class="left">
            <div class="title big">{{ $t("More then 1K offers") }}</div>
            <router-link to="/products" class="button">
              <span>{{ $t("View all offers") }}</span>
            </router-link>
          </div>
          <div class="right">
            <ul class="list">
              <li class="item desc">{{ $t("You may pick from a wide variety of skins") }}.</li>
              <li class="item desc">{{ $t("Discounted CS2 skins are available at low prices") }}.</li>
              <li class="item desc">{{ $t("You may now get Dragon lore and other legendary goods") }}.</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="section improve-section">
        <div class="wrapper">
          <div class="title big">
            {{ $t("Improve your inventory with BuskoSkins") }}
          </div>
          <button class="button" @click="$parent.openSignUpModal">
            {{ $t("Sign up") }}
          </button>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import SlickItem from "../components/SlickItem.vue";
import Features from "../components/Features.vue";
import RandomCasesSlider from "../components/RandomCasesSlider.vue";
import LastSales from "../components/LastSales.vue";
export default {
  name: "Home",
  props: ["addToCartChosenItem", "currency"],
  components: {
    SlickItem,
    Features,
    RandomCasesSlider,
    LastSales,
  },
  data: function () {
    return {
      imgDomain: "",
    };
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
  },
  methods: {
    goToProduct(item) {
      this.$emit("goToProduct", item);
    },
    addToCart(item) {
      this.$emit("addToCart", item.id, item.item_type);
    },
  },
};
</script>
