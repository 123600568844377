<template>
  <div class="slider-container">
    <slick
      ref="slick"
      :options="slickOptions"
      class="slider products"
      @swipe="handleSwipe"
    >
      <div
        class="item"
        v-for="(item) in list"
        :key="item.id"
      >
        <ProductCard
          :item="item"
          :tag="tag"
          :addToCartChosenItem="addToCartChosenItem"
          :sliderIsClickble="sliderIsClickble"
          @goToProduct="goToProduct"
          @addToCart="addToCart"
        />
      </div>
    </slick>
  </div>
</template>

<script>
import ProductCard from '../components/ProductCard.vue'
import Slick from 'vue-slick';

export default {
  name: 'SlickItem',
  props: ['list', 'tag', 'addToCartChosenItem', 'currency'],
  components: {
    Slick,
    ProductCard
  },
  data: function () {
    return {
      imgDomain: '',
      sliderIsClickble: true,
      slickOptions: {
        slidesToScroll: 6,
        slidesToShow: 6,
        arrows: true,
        // centerMode: true,
        // centerPadding: '20px',
        infinite: true,
        variableWidth: true,
        autoplay: true,
        autoplaySpeed: 2500,
        pauseOnHover: true,
        dots: false,
        responsive: [
          {
            breakpoint: 1601,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 5,
            }
          },
          {
            breakpoint: 1201,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
            }
          },
          {
            breakpoint: 901,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
            }
          },
          {
            breakpoint: 601,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            }
          },
          {
            breakpoint: 450,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            }
          }
        ]
      },
    }
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
  },
  methods: {
    handleSwipe() {
      let self = this;
      self.sliderIsClickble = false;
      setTimeout(() => {
        self.sliderIsClickble = true;
      }, 300)
    },
    addToCart(item) {
      this.$emit('addToCart', item)
    },
    goToProduct(item) {
      this.$emit('goToProduct', item)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep(.slick-slider) {
  padding: 0 20px;
  margin-bottom: 45px;

  .slick-slide {
    margin: 0 7.5px;
  }

  .slick-prev:before,
  .slick-next:before {
    font-size: 0;
  }
}
</style>