<template>
  <main class="main product-page page-inside">
    <div class="main-wrapper">
      <section class="section hero-section product-list-section">
        <div class="wrapper">
          <transition name="fade">
            <div class="product-preview" v-if="product">
              <div class="preview-container">
                <div class="preview-wrapper">
                  <div class="preview">
                    <transition name="fade">
                      <span class="title added" v-if="addToCartChosenItem === product.id">Added</span>
                    </transition>
                    <img :src="getImageUrl" class="img" />
                  </div>

                  <div class="text">
                    <div class="desc">
                      {{ product.item_type == 'pack' ? 'Pack' : product.type }}
                    </div>
                    <div class="title">
                      {{ product.name || product.title }}
                    </div>
                    <div class="desc" v-if="product.content_desc && product.item_type == 'pack'"
                      v-html="product.content_desc">
                    </div>

                    <div class="price-container" :class="{ 'has-discount': hasDiscount }">
                      <span class="old-price" v-if="hasDiscount">
                        {{ product.old_price }}
                        <span class="currency">
                          {{ currencySymbol }}
                        </span>
                      </span>
                      <span class="current-price">
                        {{ product.price }}
                        <span class="currency">
                          {{ currencySymbol }}
                        </span>
                      </span>
                    </div>


                    <div class="last-sale-button-container">
                      <div
                        v-if="product && !product.item_type && product.last_sale && product.last_sale.date && product.last_sale.price"
                        class="last-sale">
                        <div class="desc">
                          {{ $t(`Last sale`) }} {{ product.last_sale.date | moment("MMM DD") }} for {{ product.last_sale.price }} {{ currencySymbol }}
                        </div>
                      </div>

                      <button class="add-to-cart button" @click="addToCart">
                        <!-- <span>{{ $t("Add to cart") }}</span> -->
                        <img src="./../assets/cart.svg" class="img" />
                      </button>

                    </div>

                    <div class="disclaimer" v-if="product.item_type == 'pack'">
                      <img src="@/assets/tooltip.svg" alt="">
                      <p>
                        Please be advised that items obtained from these cases are distributed on a purely random basis.
                        The value of the items you receive can vary significantly, generally ranging from equal to the
                        cost of the case itself to potentially slightly exceeding the case's value. It's important to
                        understand that due to the random nature of item distribution, we cannot guarantee the exact
                        value or specific items you will receive from any given case. By purchasing a case, you
                        acknowledge and accept these terms and conditions of the platform. If you have any questions or
                        concerns, please contact our customer support before making a purchase.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </section>
    </div>
  </main>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ProductPage",
  props: ["addToCartChosenItem"],
  components: {},
  data() {
    return {
      product: null,
    };
  },
  mounted() {
    this.fetchProduct();
  },
  methods: {
    addToCart() {
      if (this.product.item_type === 'pack') {
        this.$emit('addToCart', this.product.id, 'pack');
      }
      else {
        this.$emit('addToCart', this.product.id, 'item');
      }
    },
    fetchProduct() {
      this.product = null;
      const productIdOrSlug = this.$route.params.id || this.$route.params.slug;
      const apiEndpoint = this.getApiEndpoint(productIdOrSlug);

      this.$http
        .get(apiEndpoint, { params: { currency: this.currency, include_price_history: true } })
        .then((res) => {
          this.product = res.data.payload;
        })
        .catch((error) => {
          console.error("Error fetching product data:", error);
          this.$router.push({ name: "PageNotFound" });
        });
    },
    getApiEndpoint(productIdOrSlug) {
      if (this.$route.path.includes('/packs/')) {
        return `${process.env.VUE_APP_API}packs/${productIdOrSlug}`;
      } else {
        return `${process.env.VUE_APP_API}items/${productIdOrSlug}`;
      }
    },
  },
  watch: {
    currency() {
      this.fetchProduct();
    },
    $route(to, from) {
      if (to.params.id !== from.params.id || to.params.slug !== from.params.slug) {
        this.fetchProduct();
      }
    },
  },
  computed: {
    ...mapGetters('app', ['currencySymbol', 'currency']),
    hasDiscount() {
      return this.product && this.product.old_price && this.product.old_price !== '0.00';
    },
    getImageUrl() {
      if (this.product && this.product.item_type === 'pack') {
        return this.product.image_url
      }
      else {
        return this.$parent.imgDomain + this.product.img_url;
      }
    },
  },
};
</script>


<style lang="scss" scoped>
.product-preview {
  font-family: 'Roboto', sans-serif;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  justify-content: center;
}

.product-preview .preview-container {
  width: 100%;
  position: relative;
}

.product-preview .preview-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.product-preview .preview {
  max-height: 384px;
  max-width: 512px;
  width: 100%;
  margin: 21px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0;
}

.product-preview .preview img {
  max-height: 384px;
  max-width: 512px;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.product-preview .preview-wrapper .text {
  max-width: 533px;
  width: 100%;
}

.product-preview .desc {
  color: #fff;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}

.product-preview .preview-wrapper .title {
  width: 100%;
  text-align: left;
  font-size: 32px;
  line-height: 38px;
  margin-top: 4px;

  &.added {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
  }
}

.product-preview .preview-wrapper .price-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 32px;
  color: #fff;

  &.has-discount {
    .current-price {
      color: #C83042;
    }
  }

  .current-price {
    color: #fff;
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    text-align: left;
  }

  .old-price {
    position: relative;
    color: #fff;
    font-size: 12px;
    font-weight: 700;
  }

  .old-price:after {
    content: '';
    width: 100%;
    height: 1.5px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background: #fff;
  }
}

.product-preview .preview-wrapper .last-sale-button-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 24px;
  margin-top: 24px;
}

.last-sale {
  max-width: fit-content;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 16.5px 16px;
  background: #3D3A44;


  .desc {
    color: #fff;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
  }
}

.product-preview .preview-wrapper .add-to-cart {
  // width: 100%;
  width: 52px;
  height: 52px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-end;
  background: linear-gradient(90deg, #52CA86 0%, #67CAB6 100%);
  opacity: 1;
  border: none;
  position: relative;
  transition: .3s;

  &:hover {
    opacity: .7;
  }

  .img {
    width: 22px;
  }
}

.disclaimer {
  color: white;
  margin-top: 20px;
  font-size: 14px;
  display: flex;
  gap: 10px;
  align-items: flex-start;
  text-align: left;
  font-family: 'Noto sans', sans-serif;

  h2 {
    font-size: 14px;
  }
}

@media screen and (max-width: 768px) {
  .preview-wrapper {
    flex-direction: column;
  }
}
</style>