<template>
    <div class="last-sales-section" v-if="sales && sales.length">
        <div class="wrapper">
            <div class="slider-header">
                <h2 class="slider-title">{{ $t('Last Sales') }}</h2>
            </div>
            <VueSlickCarousel v-bind="slickOptions" ref="carousel">
                <div class="carousel-item" v-for="(sale, index) in sales" :key="index">
                    <ProductCard :item="sale" :isLastSaleCarousel="true" @goToProduct="(sale) => handleClick(sale)"
                        @addToCart="handleAddToCart(sale)" :addToCartChosenItem="addToCartChosenItem"
                        :sliderIsClickble="true" />
                </div>
            </VueSlickCarousel>
        </div>
    </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import ProductCard from './ProductCard.vue'
import { mapGetters } from 'vuex';

export default {
    name: 'LastSales',
    components: {
        ProductCard,
        VueSlickCarousel
    },
    props: {
        addToCartChosenItem: {
            type: String
        }
    },
    data() {
        return {
            sales: [],
            slickOptions: {
                dots: false,
                infinite: true,
                arrows: false,
                slidesToShow: 5,
                slidesToScroll: 1,
                autoplay: true,
                autoplaySpeed: 1000,
                pauseOnHover: true,
                pauseOnFocus: true,
                pauseOnDotsHover: true,
                responsive: [
                    {
                        breakpoint: 1025,
                        settings: {
                            slidesToShow: 4,
                        }
                    },
                    {
                        breakpoint: 769,
                        settings: {
                            slidesToShow: 3,
                        }
                    },
                    {
                        breakpoint: 601,
                        settings: {
                            slidesToShow: 2,
                        }
                    },
                    {
                        breakpoint: 450,
                        settings: {
                            slidesToShow: 1,
                        }
                    }
                ]
            }
        }
    },
    computed: {
      ...mapGetters('app', ['currency']),
    },
    mounted() {
        this.fetchLastSales()
    },
    methods: {
        handleAddToCart(item) {
            this.$emit('addToCart', item, item.id, item.item_type)
        },
        handleClick(item) {
            this.$emit('goToProduct', item)
        },
        fetchLastSales() {
            this.$http.get(process.env.VUE_APP_API + 'items/rand-type-items?currency=' + this.currency)
                .then(response => {
                    if (response.status === 200) {
                        this.sales = response.data.payload
                    }
                })
                .catch(error => {
                    console.error('Error fetching last sales:', error)
                })
        },
    }
}
</script>

<style scoped lang="scss">
.last-sales-section {
    margin-top: 72px;
    font-family: 'Roboto', sans-serif;

    ::v-deep(.slick-slider) {
        padding-bottom: 0;

        .slick-list {
            overflow-y: visible;
        }
    }

    .slider-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: 56px;
        margin-bottom: 40px;
        color: white;
        font-size: 48px;
        font-weight: 700;
    }

    .carousel-item {
        padding: 0 7.5px;
    }
}
</style>