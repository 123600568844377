var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.randomCases && _vm.randomCases.length)?_c('div',{staticClass:"random-cases-section"},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"section"},[_c('div',{staticClass:"slider-header"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"bottom"},[_c('h2',{staticClass:"slider-title"},[_vm._v(" "+_vm._s(_vm.$te("packs-section-title") ? _vm.$t("packs-section-title") : "Fair-Value Random Skin Bundles")+" ")]),_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                content: _vm.tooltipContent,
                html: true,
                triggers: ['click'],
                placement: 'top',
                autoHide: true,
                theme: 'random-cases-tooltip',
              }),expression:"{\n                content: tooltipContent,\n                html: true,\n                triggers: ['click'],\n                placement: 'top',\n                autoHide: true,\n                theme: 'random-cases-tooltip',\n              }"}],staticClass:"info-icon"},[_c('img',{attrs:{"src":require("@/assets/tooltip.svg"),"alt":""}})])])]),_c('div',{staticClass:"right"},[_c('router-link',{staticClass:"link",attrs:{"to":"/products/cases"}},[_vm._v(_vm._s(_vm.$t(`View all`)))])],1)]),_c('div',{staticClass:"desc"},[_vm._v(" "+_vm._s(_vm.$te("packs-section-desc") ? _vm.$t("packs-section-desc") : "Purchase a skin pack where the combined value of the skins always meets or exceeds the price you pay. No gamble—just great skins.")+" ")])]),_c('div',{staticClass:"random-cases-slider",on:{"click":_vm.handleClick}},[_c('VueSlickCarousel',_vm._b({ref:"carousel"},'VueSlickCarousel',_vm.slickOptions,false),_vm._l((_vm.randomCases),function(card){return _c('div',{key:card.slug,staticClass:"card",attrs:{"data-slug":card.slug}},[_c('div',{staticClass:"card-wrapper",class:card.type},[_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"card-image-container"},[_c('img',{staticClass:"card-image",attrs:{"src":card.image_url,"alt":card.name}})]),_c('div',{staticClass:"card-footer"},[_c('h3',[_vm._v(_vm._s(card.name))]),_c('div',{staticClass:"price-badge"},[_vm._v(" "+_vm._s(Math.floor(parseFloat(card.price)))+" "+_vm._s(_vm.currencySymbol)+" ")])])])])])}),0)],1)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }