<template>
  <div class="item-wrapper" :class="{ 'last-sale-carousel': isLastSaleCarousel }">
    <div v-if="hasDiscount && !isLastSaleCarousel" class="discount-badge">
      <img src="@/assets/discount.svg" alt="Discount" class="discount-icon">
    </div>
    <div v-if="tag && !isLastSaleCarousel" class="tag">
      <span>{{ tag }}</span>
    </div>
    <div class="preview">
      <img @click="goToSliderItem(item)" :src="getImageUrl(item)" class="img" />
      <transition name="fade">
        <span class="title" v-if="addToCartChosenItem == item.id && !isLastSaleCarousel">{{ $t("Added") }}</span>
      </transition>
    </div>
    <a @click="goToSliderItem(item)" class="desc">{{ getTitle(item) }}</a>

    <div v-if="!isLastSaleCarousel" class="price-button-container" :class="{ 'has-discount': hasDiscount }">
      <div class="price-container">
        <span class="old-price" v-if="hasDiscount">
          {{ item.old_price }}
          <span class="currency">
            {{ currencySymbol }}
          </span>
        </span>
        <span class="current-price">
          {{ item.price }}
          <span class="currency">
            {{ currencySymbol }}
          </span>
        </span>
      </div>
      <a @click="$emit('addToCart', item)" class="link button">
        <!-- <span>{{ $t("Add to cart") }}</span> -->
        <img src="./../assets/cart.svg" class="img" />
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ProductCard",
  props: ["item", "addToCartChosenItem", "sliderIsClickble", "tag", "isLastSaleCarousel"],
  data: function () {
    return {
      imgDomain: "",
    };
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
  },
  watch: {
  },
  methods: {
    getTitle(item) {
      return item.title || item.name;
    },
    getImageUrl(item) {
      return item.item_type == 'pack' ? item.img_url : this.imgDomain + item.img_url;
    },
    goToSliderItem(item) {
      let self = this;
      setTimeout(() => {
        if (self.sliderIsClickble == true) {
          self.$emit("goToProduct", item);
        }
      }, 200);
    },
  },
  computed: {
    ...mapGetters('app', ['currencySymbol']),
    ...mapGetters('discount', ['hasActiveDiscount']),
    hasDiscount() {
      return this.item && this.item.old_price && this.item.old_price !== '0.00';
    },
  }
};
</script>
<style scoped lang="scss">
.item-wrapper {
  background: #3D3A44;
  padding: 24px;
  font-family: 'Roboto', sans-serif;
  position: relative;

  &.last-sale-carousel {
    .price-button-container {
      display: none;
    }
  }

  .discount-badge {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    align-items: center;
    border-radius: 12px;
    z-index: 1;
  }

  .discount-icon {
    width: 24px;
    height: 24px;
    pointer-events: none;
  }

  .preview {
    max-width: 152px;
    width: 100%;
    height: 98px;
    margin: 0 auto;
    margin-bottom: 12px;
    cursor: pointer;

    .img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      -o-object-fit: contain;
      object-position: center;
    }
  }

  a.desc {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    line-height: 24px;
    height: 48px;
    max-width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .price-button-container {
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &.has-discount {
      .price-container {
        flex-direction: column;
      }

      .current-price {
        color: #C83042;
      }
    }

    .price-container {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      color: #fff;
      font-weight: 700;
      font-size: 20px;
      line-height: 1;
    }

    .old-price,
    .current-price {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 118px;
      position: relative;
    }

    .current-price {
      color: #fff;
      font-weight: 700;
      font-size: 20px;
    }

    .old-price {
      font-size: 12px;
      font-weight: 700;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        border-bottom: 1.5px solid #FFFFFF;
        transform: translateY(-50%);
      }
    }

    .link {
      // width: 100%;
      width: 52px;
      height: 52px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      align-self: flex-end;
      background: linear-gradient(90deg, #52CA86 0%, #67CAB6 100%);
      opacity: 1;
      border: none;
      position: relative;
      transition: .3s;
    }

    .link .img {
      width: 22px;
    }

    .link:hover {
      opacity: .7;
    }

  }
}
</style>