<template>
  <main class="main page-inside payment-result-page">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="wrapper">
          <div class="title big">
            Thank you for your purchase! You will receive all skins files to your provided e-mail. In case of any
            questions, please contact us at support@{{ $parent.hostname }}
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'PaymentSuccessPage',
  async mounted() {
    await this.fetchActiveDiscount(this.currency);
    if (this.hasActiveDiscount) {
      this.openDiscountModal();
    }
  },
  computed: {
    ...mapGetters('discount', ['hasActiveDiscount']),
    ...mapGetters('app', ['currency']),
  },
  methods: {
    ...mapActions('discount', ['fetchActiveDiscount', 'openDiscountModal']),
  },
};
</script>