<template>
  <div class="random-cases-section" v-if="randomCases && randomCases.length">
    <div class="wrapper">
      <div class="section">
        <div class="slider-header">
          <div class="left">
            <!-- <div class="top">
                            <div class="new-badge">{{ $t(`New!`) }}</div>
                        </div> -->
            <div class="bottom">
              <h2 class="slider-title">
                {{
                  $te("packs-section-title")
                    ? $t("packs-section-title")
                    : "Fair-Value Random Skin Bundles"
                }}
              </h2>
              <i
                class="info-icon"
                v-tooltip="{
                  content: tooltipContent,
                  html: true,
                  triggers: ['click'],
                  placement: 'top',
                  autoHide: true,
                  theme: 'random-cases-tooltip',
                }"
              >
                <img src="@/assets/tooltip.svg" alt="" />
              </i>
            </div>
          </div>
          <div class="right">
            <router-link to="/products/cases" class="link">{{
              $t(`View all`)
            }}</router-link>
          </div>
        </div>

        <div class="desc">
          {{
            $te("packs-section-desc")
              ? $t("packs-section-desc")
              : "Purchase a skin pack where the combined value of the skins always meets or exceeds the price you pay. No gamble—just great skins."
          }}
        </div>
      </div>

      <div class="random-cases-slider" @click="handleClick">
        <VueSlickCarousel v-bind="slickOptions" ref="carousel">
          <div
            v-for="card in randomCases"
            :key="card.slug"
            class="card"
            :data-slug="card.slug"
          >
            <div class="card-wrapper" :class="card.type">
              <div class="card-content">
                <div class="card-image-container">
                  <img
                    :src="card.image_url"
                    :alt="card.name"
                    class="card-image"
                  />
                </div>
                <div class="card-footer">
                  <h3>{{ card.name }}</h3>
                  <div class="price-badge">
                    {{ Math.floor(parseFloat(card.price)) }}
                    {{ currencySymbol }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </VueSlickCarousel>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  name: "RandomCasesSlider",
  components: { VueSlickCarousel },
  created() {
    this.fetchRandomCases();
  },
  mounted() {
    this.$nextTick(this.initializeCarousel);
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  data() {
    return {
      tooltipContent: `
                <h2 class="tooltip-title">Random Packs</h2>
                <p class="tooltip-description">
                Please be advised that items obtained from these cases are distributed on a purely random basis. The value of the items you receive can vary significantly, generally ranging from equal to the cost of the case itself to potentially slightly exceeding the case's value. It's important to understand that due to the random nature of item distribution, we cannot guarantee the exact value or specific items you will receive from any given case. By purchasing a case, you acknowledge and accept these terms and conditions of the platform. If you have any questions or concerns, please contact our customer support before making a purchase.
                </p>
            `,
      slickOptions: {
        infinite: true,
        slidesToShow: this.calculateSlidesToShow(),
        slidesToScroll: this.calculateSlidesToScroll(),
        draggable: true,
        // centerMode: false,
        // initialSlide: 0,
        autoplay: true,
        autoplaySpeed: 4000,
        // speed: 500,
        arrows: true,
        pauseOnHover: true,
        dots: false,
        // variableWidth: true,
        // centerMode: true,
        // centerPadding: '60px',
      },
      randomCases: [],
    };
  },
  computed: {
    ...mapGetters("app", ["currencySymbol", "currency"]),
  },
  watch: {
    currency() {
      this.fetchRandomCases();
    },
  },
  methods: {
    addToCart(item) {
      this.$parent.addToCart(item.id, item.item_type);
    },
    handleResize() {
      this.slickOptions.slidesToShow = this.calculateSlidesToShow();
      this.slickOptions.slidesToScroll = this.calculateSlidesToScroll();
    },
    calculateSlidesToShow() {
      let containerWidth;
      if (innerWidth >= 1440) {
        containerWidth = 1280; // fixed container width for large screens
      } else {
        containerWidth = innerWidth - 40;
      }
      const cardWidth = 280;
      const gap = 48;
      const slidesToShow = Math.floor(
        (containerWidth + gap) / (cardWidth + gap)
      );
      return slidesToShow;
    },
    calculateSlidesToScroll() {
      return this.calculateSlidesToShow();
    },
    initializeCarousel() {
      const carousel = this.$refs.carousel;
      if (carousel && carousel.$el) {
        const slickList = carousel.$el.querySelector(".slick-list");
        if (slickList) {
          slickList.addEventListener("mousedown", () => {
            this.isDragging = false;
          });
          slickList.addEventListener("mousemove", () => {
            this.isDragging = true;
          });
          slickList.addEventListener("mouseup", () => {
            setTimeout(() => {
              this.isDragging = false;
            }, 10);
          });
        }
      }
    },
    handleClick(event) {
      const card = event.target.closest(".card");
      if (card && !this.isDragging) {
        const slug = card.dataset.slug;
        this.$router.push(`/packs/${slug}`);
      }
    },
    async fetchRandomCases() {
      this.$http
        .get(process.env.VUE_APP_API + "packs" + "?currency=" + this.currency)
        .then((res) => {
          if (res.status == 200) {
            this.randomCases = res.data.payload;
          }
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.$parent.openSignInModal();
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.random-cases-section {
  margin-top: 120px;
  font-family: "Noto Sans", sans-serif;

  ::v-deep(.slick-slider) {
    padding: 0;
    margin: 0;

    &::before,
    &::after {
      all: unset;
    }

    .slick-arrow {
      all: unset;
      position: absolute;
      cursor: pointer;
      font-size: 0;
      z-index: 3;
      top: 50%;
      transform: translateY(-50%);
      height: 100%;
      width: 35px;

      &.slick-prev {
        left: 5px;
      }

      &.slick-next {
        right: 5px;
      }
    }
  }

  .slider-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 75px;
    margin-bottom: 12px;
    flex-wrap: wrap;

    .left {
      .bottom {
        display: flex;
        flex-wrap: wrap;
        gap: 11px;
      }

      .slider-title {
        font-weight: 600;
        font-size: calc(clamp(1.313rem, 0.83rem + 2.411vw, 3rem));
        color: white;
      }

      .info-icon {
        height: 100%;
      }

      .info-icon img {
        display: block;
        cursor: pointer;
      }
    }

    .link {
      font-weight: 500;
      font-size: 16px;
      line-height: 26px;
      color: #fff;
    }
  }

  .desc {
    font-size: 12px;
    font-weight: 400;
    line-height: 1;
    margin-bottom: 48px;
  }
}

.random-cases-slider {
  height: 294px;
}

.slider-header {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 20px;
}

.card-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 280px;
  height: 240px;
  border-radius: 24px;
  position: relative;
  transition: all 0.15s ease;
  margin: 0 16px;
  cursor: pointer;

  &:hover {
    height: 294px;

    .card-footer {
      height: 90px;
      width: 300px;
      background: #55cb8b;
      transition: all 0.3s ease;

      .price-badge {
        background: #3d3a44;
      }
    }

    .card-image-container {
      position: absolute;
      top: 0;
      width: 220px;
      height: 220px;
      transition: all 0.3s ease;
    }
  }
}

.card-wrapper .card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.card {
  display: flex !important;
  align-items: center;
  justify-content: center;
  height: 294px;
  width: 300px;
}

.price-badge {
  /* position: absolute; */
  /* top: 10px; */
  /* left: 10px; */
  width: max-content;
  max-width: 90px;
  height: 36px;
  background: linear-gradient(90deg, #52ca86 0%, #67cab6 100%);
  color: white;
  border-radius: 4px;
  z-index: 2;
  padding: 8.5px 24.5px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-wrap: nowrap;
}

.card-image-container {
  position: relative;
  width: 180px;
  height: 180px;
  pointer-events: none;
  /* margin: 0 auto; */
}

.card-image {
  position: relative;
  z-index: 1;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.card-footer {
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: #3d3a44;
  border-radius: 4px;
  padding: 20px 24px;
  transition: background 0.15s ease, height 0.15s ease;

  h3 {
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    color: #fff;
    height: 19px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 140px;
    text-align: left;
  }

  p {
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    height: 44px;
    color: #fff;
    opacity: 0.5;
    text-align: center;
    max-width: 206px;
    text-wrap: wrap;
    max-height: 44px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.new-badge {
  background: #ec7633;
  color: white;
  text-transform: uppercase;
  border-radius: 24px;
  width: 64px;
  height: 24px;
  padding: 4px 16px;

  font-size: 12px;
  line-height: 16px;
  text-wrap: nowrap;
}

@media (max-width: 670px) {
  .random-cases-section .slider-header {
    line-height: 1.2;
  }

  .card-wrapper {
    margin: 0;
    width: 288px;
  }
}

@media (max-width: 460px) {
  // .random-cases-section {
  //     .slider-header {
  //         line-height: 26px;
  //         flex-direction: column;
  //         align-items: flex-start;
  //     }
  // }
}
</style>
