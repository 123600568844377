<template>
  <main class="main page-inside">
    <div class="main-wrapper">
      <section class="section product-list-section">
        <div class="wrapper">

          <div class="filters-container">
            <div class="filters-text">Filters:</div>

            <!-- Price Filter -->
            <div class="filter-dropdown price-filter" ref="priceDropdownRef"
              @click="toggleDropdown('priceListIsVisible')">
              <span class="dropdown-text">Price {{ barMinValue }} - {{ barMaxValue }}</span>
              <img src="@/assets/arrow-down.svg" :class="{ 'rotated': priceListIsVisible }" alt="arrow" />

              <div v-if="priceListIsVisible" class="dropdown-menu price-dropdown" @click.stop>
                <div class="price-input-container">
                  <label class="price-label">
                    <span class="min">Min:</span>
                    <input type="number" v-model.number="barMinValue" class="price-input" placeholder="0"
                      @input="updateMinValue" />
                  </label>
                  <input type="range" v-model.number="barMinValue" :min="0" :max="10000" @input="updateMinValue" />
                  <label class="price-label">
                    <span class="max">Max:</span>
                    <input type="number" v-model.number="barMaxValue" class="price-input" placeholder="10000"
                      @input="updateMaxValue" />
                  </label>
                  <input type="range" v-model.number="barMaxValue" :min="0" :max="10000" @input="updateMaxValue" />
                </div>
              </div>

            </div>

            <!-- Type Filter -->
            <div class="filter-dropdown type-filter" ref="typeDropdownRef" @click="toggleDropdown('typeListIsVisible')">
              <div>
                <!-- <div class="filter-label">Type</div> -->
                <div class="filter-value">{{ category ? category : 'All' }}</div>
                <!-- <div class="filter-value">{{  $t('Type') }}</div> -->
              </div>
              <img src="@/assets/arrow-down.svg" :class="{ 'rotated': typeListIsVisible }" alt="arrow" />
              <div v-if="typeListIsVisible" class="dropdown-menu" @click.stop>
                <label v-for="item in categoryOptions" :key="item" class="checkbox-label">
                  <div class="checkbox-label-wrapper">
                    <input type="radio" :value="item" v-model="category" />
                    <div class="checkbox"></div>
                    <span class="desc">{{ item }}</span>
                  </div>
                </label>
              </div>
            </div>

            <!-- Quality Filter -->
            <div class="filter-dropdown quality-filter" ref="qualityDropdownRef"
              @click="toggleDropdown('qualityListIsVisible')">
              <div>
                <!-- <div class="filter-label">Quality</div> -->
                <div class="filter-value">{{ quality ? quality : 'All' }}</div>
              </div>
              <img src="@/assets/arrow-down.svg" :class="{ 'rotated': qualityListIsVisible }" alt="arrow" />
              <div v-if="qualityListIsVisible" class="dropdown-menu" @click.stop>
                <label v-for="item in qualityOptions" :key="item" class="checkbox-label">
                  <div class="checkbox-label-wrapper">
                    <input type="radio" :value="item" v-model="quality" />
                    <div class="checkbox"></div>
                    <span class="desc">{{ item }}</span>
                  </div>
                </label>
              </div>
            </div>

            <div class="sort-container">
              <span class="sort-text">Sort:</span>
              <div class="filter-dropdown sort-dropdown" ref="sortDropdownRef"
                @click="toggleDropdown('sortListIsVisible')">
                <div>
                  <!-- <div class="filter-label">Sort by</div> -->
                  <div class="filter-value">{{ sort === 'desc' ? 'Highest price' : 'Lowest price' }}</div>
                </div>
                <img src="@/assets/arrow-down.svg" :class="{ 'rotated': sortListIsVisible }" alt="arrow" />
                <div v-if="sortListIsVisible" class="dropdown-menu" @click.stop>
                  <label class="checkbox-label">
                    <div class="checkbox-label-wrapper">
                      <input type="radio" name="desc" value="desc" v-model="sort" />
                      <div class="checkbox"></div>
                      <span class="desc">{{ $t("Highest price first") }}</span>
                    </div>
                  </label>
                  <label class="checkbox-label">
                    <div class="checkbox-label-wrapper">
                      <input type="radio" name="asc" value="asc" v-model="sort" />
                      <div class="checkbox"></div>
                      <span class="desc">{{ $t("Lowest price first") }}</span>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div class="products-container">
            <Transition>
              <div class="list products" v-if="productList && productList.length > 0">
                <div class="item" v-for="(item, i) in productList" :key="i">
                  <ProductCard :item="item" :addToCartChosenItem="addToCartChosenItem" :sliderIsClickble="true"
                    @goToProduct="goToProduct" @addToCart="addToCart" />
                </div>
              </div>

              <!-- <div v-else-if="isFetchingProducts" class="loading-products">
                <p>{{ $t("Loading products...") }}</p>
              </div> -->

              <div v-else-if="(!productList || !productList.length) && !isFetchingProducts" class="no-products">
                <p>{{ $t("No products found") }}</p>
                <button class="button" @click="resetFilters">{{ $t("Reset filters") }}</button>
              </div>

            </Transition>
          </div>

          <CustomPagination v-if="totalProducts" :total-pages="totalPages" :per-page.sync="perPage"
            :current-page.sync="activePage" @update:currentPage="changePage" @perPageChange="handlePerPageChange" />
        </div>
      </section>
    </div>
  </main>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { debounce } from 'lodash';
import { onClickOutside } from '@vueuse/core';
import ProductCard from "../components/ProductCard.vue";
import CustomPagination from '../components/CustomPagination.vue';

export default {
  name: "ProductListPage",
  props: {
    addToCartChosenItem: [String, Number]
  },
  components: {
    ProductCard,
    CustomPagination
  },
  data() {
    return {
      imgDomain: "",
      sort: "desc",
      category: "All",
      quality: "All",
      categoryOptions: [],
      qualityOptions: [],
      barMinValue: 0,
      barMaxValue: 10000,
      productList: {},

      totalPages: 1,
      activePage: 1,
      perPage: 20,

      isFetchingProducts: false,

      totalProducts: null,
      priceListIsVisible: false,
      typeListIsVisible: false,
      qualityListIsVisible: false,
      sortListIsVisible: false,
      specialCategory: "",
    };
  },
  computed: {
    ...mapGetters('app', ['currencySymbol', 'currency'])
  },
  watch: {
    currency: function () {
      this.filterProducts();
    },
    barMinValue: function () {
      this.activePage = 1;
      this.debounceFilterProducts();
    },
    barMaxValue: function () {
      this.activePage = 1;
      this.debounceFilterProducts();
    },
    sort() {
      this.activePage = 1;
      this.filterProducts();
    },
    quality() {
      this.activePage = 1;
      this.filterProducts();
    },
    category() {
      this.activePage = 1;
      this.filterProducts();
    },
    $route() {
      this.activePage = 1;
      this.checkRouteParams();
    },
  },
  mounted() {
    this.checkRouteParams();
    this.getCategoryOptions();
    this.getQualityOptions();
    if (this.$parent.isAuth) {
      this.fetchActiveDiscount(this.currency);
    }
    this.setupClickOutsideListeners();
  },
  methods: {
    ...mapActions('discount', ['fetchActiveDiscount']),
    updateMinValue: debounce(function (event) {
      const newValue = typeof event === 'object' ? Number(event.target.value) : Number(event);
      this.barMinValue = Math.min(newValue, this.barMaxValue);
    }, 350),

    updateMaxValue: debounce(function (event) {
      const newValue = typeof event === 'object' ? Number(event.target.value) : Number(event);
      this.barMaxValue = Math.max(newValue, this.barMinValue);
    }, 350),
    handlePerPageChange(newPerPage) {
      this.perPage = newPerPage;
      this.activePage = 1;
      this.filterProducts();
    },
    toggleDropdown(dropdown) {
      this[dropdown] = !this[dropdown];
      ['priceListIsVisible', 'typeListIsVisible', 'qualityListIsVisible', 'sortListIsVisible'].forEach(key => {
        if (key !== dropdown) {
          this[key] = false;
        }
      });
    },
    setupClickOutsideListeners() {
      onClickOutside(this.$refs.priceDropdownRef, () => this.priceListIsVisible = false);
      onClickOutside(this.$refs.typeDropdownRef, () => this.typeListIsVisible = false);
      onClickOutside(this.$refs.qualityDropdownRef, () => this.qualityListIsVisible = false);
      onClickOutside(this.$refs.sortDropdownRef, () => this.sortListIsVisible = false);
    },
    checkRouteParams() {
      if (this.$route.params.type === "revolution-collection") {
        this.specialCategory = this.$route.params.type;
      } else if (this.$route.params.type && this.$route.params.type !== "all") {
        this.category = this.$route.params.type;
        this.specialCategory = "";
      } else {
        this.category = "All";
        this.specialCategory = "";
      }
      this.activePage = 1;
      this.filterProducts();
    },
    resetFilters() {
      this.quality = "All";
      this.category = "All";
      this.barMinValue = 0;
      this.barMaxValue = 10000;
    },
    resetPageAndFilter() {
      this.activePage = 1;
      this.debounceFilterProducts();
    },
    debounceFilterProducts: debounce(function () {
      this.filterProducts();
    }, 300),
    openFilter() {
      this.filtersIsVisible = !this.filtersIsVisible;
    },
    filterProducts() {
      this.isFetchingProducts = true;
      const params = new URLSearchParams({
        type: this.category !== "All" ? this.category : "",
        quality: this.quality !== "All" ? this.quality : "",
        price_from: this.barMinValue,
        price_till: this.barMaxValue,
        sort: this.sort,
        page: this.activePage,
        limit: this.perPage,
        category: this.specialCategory,
        currency: this.currency
      });

      this.$http.get(`${process.env.VUE_APP_API}items/list?${params}`)
        .then((res) => {
          this.productList = res.data.payload;
          this.totalProducts = res.data.meta.total;
          this.totalPages = res.data.meta.pages;
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.$parent.openSignInModal();
          }
        })
        .finally(() => {
          this.isFetchingProducts = false;
        });
    },
    changePage: debounce(function (page) {
      this.activePage = page;
      this.filterProducts();
    }, 250),
    updatePerPage(newPerPage) {
      this.perPage = newPerPage;
      this.resetPageAndFilter();
    },
    getCategoryOptions() {
      this.$http.get(`${process.env.VUE_APP_API}items/filter-data`)
        .then((res) => {
          this.categoryOptions = ["All", "cases", ...res.data.payload.types];
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.$parent.openSignInModal();
          }
        });
    },
    getQualityOptions() {
      this.$http.get(`${process.env.VUE_APP_API}items/filter-data`)
        .then((res) => {
          this.qualityOptions = ["All", ...res.data.payload.qualities];
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.$parent.openSignInModal();
          }
        });
    },
    addToCart(item) {
      this.$emit("addToCart", item.id, item.item_type);
    },
    goToProduct(item) {
      this.$emit("goToProduct", item);
    },
  },
};
</script>

<style scoped lang="scss">
.product-list-section {
  font-family: 'Roboto', sans-serif;
}

.filters-container {
  width: 100%;
  color: white;
  display: flex;
  align-items: center;
}

.filters-text,
.sort-text {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  margin-right: 16px;
}

.filter-dropdown {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 26px 16px 20px;
  border-radius: 4px;
  // border: 1px solid #6d7a94;
  background: #3D3A44;

  cursor: pointer;
  margin-right: 16px;
  max-width: 234px;
  width: 100%;
  height: 56px;
  position: relative;

  &.price-filter {
    color: #a8b2bd;
  }

  &.sort-dropdown {
    margin-right: 0;
  }

  .filter-label {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: #a8b2bd;
  }

  .filter-value {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-transform: lowercase;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  img {
    width: 12px;
    height: 6px;
    transition: transform 0.3s ease;

    &.rotated {
      transform: rotate(180deg);
    }
  }

  .dropdown-menu {
    position: absolute;
    top: 60px;
    left: 0;
    padding: 16px 20px;
    // border: 1px solid #6d7a94;
    width: 100%;
    max-height: 300px;

    border-radius: 4px;
    background: #3D3A44;

    overflow-y: auto;
    z-index: 10;
    display: flex;
    flex-direction: column;
    gap: 8px;
    text-transform: capitalize;
    cursor: initial;

    .checkbox-label+.checkbox-label {
      margin-top: 0;
    }

    .checkbox-label {
      cursor: pointer;

      .desc {
        font-size: 16px;
        color: #fff;
        line-height: 24px;
      }
    }

    .desc {
      font-size: 14px;
      line-height: 24px;
      color: #9898A9;
      text-transform: capitalize;
    }

  }

  .price-dropdown {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    // border: 1px solid #1C1A24;
  }

  .price-input-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    // justify-content: space-between;
    width: 100%;
    margin-bottom: 8px;

    .price-label {
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
    }

    .price-label .min,
    .price-label .max {
      width: 40px;
      display: inline-block;
    }

  }

  .price-input {
    width: 100%;
    padding: 8px;
    // border: 1px solid #6d7a94;
    border-radius: 8px;
    background-color: #1C1A24;
    color: white;
  }
}

.sort-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.no-products,
.loading-products {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 100%;
  height: 282px;
  color: white;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
}

@media (max-width: 1200px) {
  .filters-container {
    flex-wrap: wrap;
  }

  .filters-text,
  .sort-text {
    align-self: flex-start;
    margin-bottom: 16px;
  }

  .sort-container {
    flex-direction: column;
  }

  .filter-dropdown,
  .sort-container {
    margin-bottom: 16px;
    width: 100%;
    min-width: 100%;
  }

  .products-container {
    // max-height: 543px;
    // overflow-y: auto;
  }
}
</style>